/* Mobile First Design  */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&family=Roboto&display=swap');
/* font-family: 'Poppins', sans-serif; */

*{
    margin: 0;
    padding: 0;
}
  
:root{
  --blue-theme: #2f618f;
  --heading: #515354;
  --heading-font: 'Poppins', sans-serif;
  --body-font: 'Roboto';
  --body-text: #787A7C;
  --link-text: #22C9EE;
  --orange-buttons: #F4A100;
}
  
body{
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}
#container {
  width: 100%;
  height: 100vh;
  background-color:var(--blue-theme);
  display: flex;
  flex-wrap: wrap;
  place-content: center;
  align-items: center;
  gap: 3rem;
  padding: 1rem;
}

.logo{
  width: clamp(30rem, 36vw, 50rem);
  height: auto;
  /* border: solid 2px red; */
  display: block;
}
.content {
  text-align: justify;
  color: #ffffff;
  font-size: 1.3rem;
  line-height: 2.3;
  /* border: solid 2px black; */
}
.heading {
  color: #fff;
  font-size: clamp(3.2rem, 6vw, 4rem);
  font-family: var(--heading-font);
  font-weight: 600;
  width: fit-content;
  margin: auto;
  /* border: solid 2px green; */
}
.heading span{
  color: var(--orange-buttons);
}
p {
  color: #fff;
  /* border: solid 2px yellow; */
  text-align: justify;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-size: clamp(1.6rem, 2.5vw, 1.8rem);
  width: clamp(35rem, 65vw, 42.5rem)
}
.social-wrapper {
  margin: 10px auto 0 auto;
  text-align: center;
  display: inline-grid;
  grid-template-columns: 1fr 1fr; /* add 1fr for third row when Yelp is live */
  grid-column-gap: clamp(1.5rem, 4vw, 10rem);
  /* border: solid 2px red; */
}
.icon {
  font-size: clamp(2.1rem, 5vh, 10rem);
}
.icon:hover {
  cursor: pointer;
  transform: scale(1.2);
}
a{
  color: #FFFF;
}
a:hover {
  color: var(--orange-buttons);
}


@media screen and (max-width: 600px) {
  .logo {
    width: clamp(20rem, 36vw, 50rem);
  }
  .heading {
    font-size: clamp(1.97rem, 6vw, 4rem);
  }
  p {
    font-size: clamp(1.2rem, 2.5vw, 1.8rem);
    width: inherit;
    max-width: 24rem;
  }
}
